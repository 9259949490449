import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import routes from "./routes";
import "./styles/app.scss";
import ModalWrapper from "./components/ModalWrapper";
import {AppContextProvider} from "./contexts/AppContext";
import AppContext from "./contexts/AppContext";
import {hitEvent, hits, logEvent, userEvents} from "./utils/log";
import * as webviewUtils from "./utils/webview";
import Loading from "./components/Loading";
import ErrorBoundary from "./components/ErrorBoundary";
import TestInstanceToast from "./components/TestInstanceToast";
import WindowSizeWatcher from "./components/WindowSizeWatcher";
import IndexPage from "./pages/index/IndexPage";
import CreatePage from "./pages/create/CreatePage";
import PackPage from "./pages/pack/PackPage";
import PacksPage from "./pages/packs/PacksPage";
import SamplePackPage from "./pages/sample_pack/SamplePackPage";
import neuroApiHelper from "./helpers/neuro-api.helper";
import {withRouter} from "react-router";
import {webviewAppIds, webviewOpenBrowser} from "./utils/webview";
import AppToast from "./components/AppToast";
import clientStorage from "./utils/client-storage";
import tooncoinHelper from "./helpers/tooncoin-helper";
import RouteChangeListener from "./components/RouteChangeListener";
import {isWebviewApp} from "./utils/config.utils";
import {getOneMorePackInAppSku, getSubscriptionSku} from "./utils/inapp";
import {signalEvent, signals} from "./utils/signals";

window.onerror = function(message, file, line, col, error) {
  hitEvent(hits.jsGlobalError, 1, true, 0);
  signalEvent(signals.jsGlobalError, 1, true);
  logEvent(userEvents.JS_GLOBAL_ERROR, {
    message,
    file,
    line,
    col,
    error: error.message,
    url: window.location.href,
  });
};

const LazyWeb3BridgePage = React.lazy(() => import("./pages/web3/BridgePage"));

class App extends React.Component {

  state = {
    isReady: false,
  };

  componentDidMount() {
    // if (isWebviewApp(webviewAppIds.tooncoin)) {
    //   document.addEventListener("click", (e) => {
    //     if (e.target.nodeName.toLowerCase() === "a") {
    //       if (e.target.href.indexOf("//toondao.com") > 0) {
    //         e.preventDefault();
    //         webviewOpenBrowser(e.target.href, window.clientConfig.isWebviewIOS ? "safari" : undefined);
    //       }
    //     }
    //   });
    // }

    if (window.clientConfig.isWebview) {
      neuroApiHelper.setUserClientData(window.clientConfig.webviewParams);
      tooncoinHelper.setUserClientData(window.clientConfig.webviewParams);
    } else {
      const [,,token] = window.clientConfig.token.split(":");
      const data = {
        app_id: window.clientConfig.query["app_id"] || webviewAppIds.photolabFree,
        aid: ("z" + token).slice(0, 32),
      };

      neuroApiHelper.setUserClientData(data);
      tooncoinHelper.setUserClientData(data);
    }

    window.webviewEventsListeners.setPrice.subscribe((data) => {
      this.context.setSkus(data);
    }, true);

    window.webviewEventsListeners.getUserIds.subscribe((data) => {
      clearTimeout(this.getUserIdsTimer);

      try {
        data = typeof data === "string" ? JSON.parse(data) : data;
      } catch (e) {
        data = {};
      }

      neuroApiHelper.setUserCredentials(data);
      neuroApiHelper.fetchPacks().then((res) => {
        const latestPack = res.packs
          .sort((a,b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
          .filter((p) => p.status === neuroApiHelper.packStatus.ready)
          .first();

        if (window.clientConfig.isWebview && latestPack) {
          webviewUtils.webviewCancelCheck(latestPack.id);
        }

        this.context.setPacks(res.packs);
        this.context.setActiveIds(res.active_ids);

        if (clientStorage.getShouldRedirectToCreate()) {
          //clientStorage.setShouldRedirectToCreate(false);
          this.props.history.push(routes.CREATE);
        }

        this.setState({isReady: true});
      });
    });

    if (window.clientConfig.isWebview) {
      webviewUtils.webviewOnPageFinished();

      if (window.clientConfig.features.isSupportGetUserIds) {
        webviewUtils.webviewGetUserIds();
        this.getUserIdsTimer = setTimeout(webviewUtils.webviewGetUserIds, 2000);
      } else {
        window.webviewEventsListeners.getUserIds.postValue({ids: []});
      }
    } else {
      window.webviewEventsListeners.getUserIds.postValue({ids: []});
    }

    window.clientConfig.platform.os.toLowerCase() === "ios" && document.body.classList.add("ios");
    document.body.classList.add("webview");
    document.body.classList.add(`app-lang--${window.clientConfig.lang}`);

    document.body.addEventListener("mousewheel", (e) => {
      // отключаем скейлинг страницы по ctrl+scroll
      if (e.ctrlKey) {
        e.preventDefault();
      }
    }, {passive: false});
  }

  render() {
    return (
      <React.Fragment>
        <RouteChangeListener onChanged={() => window.scrollTo(0, 0)} />
        <WindowSizeWatcher />
        <AppToast {...this.context.toast} />
        <TestInstanceToast />
        <ErrorBoundary>
          <Loading {...this.context.loader} />
          {this.state.isReady && <Switch>
            <Route exact path={routes.INDEX} render={(props) => <IndexPage {...props} />} />
            <Route exact path={routes.CREATE} render={(props) => <CreatePage {...props} />} />
            <Route exact path={routes.PACK} render={(props) => <PackPage {...props} />} />
            <Route exact path={routes.PACKS} render={(props) => <PacksPage {...props} />} />
            <Route exact path={routes.SAMPLE_PACK} render={(props) => <SamplePackPage {...props} />} />
            <Route exact path={routes.WEB3_BRIDGE} render={(props) => <LazyWeb3BridgePage {...props} />} />
          </Switch>}
          <ModalWrapper />
        </ErrorBoundary>
      </React.Fragment>
    );
  }
}

App.contextType = AppContext;

const AppWithRouter = withRouter(App);

ReactDOM.createRoot(document.getElementById("root")).render(
  <AppContextProvider>
    <BrowserRouter>
      <AppWithRouter />
    </BrowserRouter>
  </AppContextProvider>
);