import {hitEvent, hits} from "../utils/log";

let userClientData = {};
let userCredentials = {
  platform: window.clientConfig.isWebviewIOS ? "ios" : "android",
};

function getFullPath() {
  const url = new URL(window.appConfig.paths.tooncoinPacksEndpoint);

  for (let key in userClientData) {
    url.searchParams.append(key, userClientData[key]);
  }

  return url.toString();
}

function defaultHandler(res) {
  if (res.ok) {
    return res.json();
  } else {
    const error = new Error("NeuroApiResponseError");
    error.name = "NeuroApiResponseError";
    error.response = res.clone();

    throw error;
  }
}

function networkErrorHandler(err) {
  const error = new Error("Network error");
  error.name = "NetworkError";
  error.parentError = err;

  throw error;
}

function defaultErrorHandler(err) {
  hitEvent(hits.packsLambdaRequestError);

  throw err;
}

function checkAddress(address) {
  return fetch(getFullPath(), {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...userCredentials,
      address,
      action: "check",
    }),
  })
    .catch(networkErrorHandler)
    .then(defaultHandler)
    .catch(defaultErrorHandler)
}

function createPack(images, gender, address, signature) {
  return fetch(getFullPath(), {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...userCredentials,
      images,
      gender,
      address,
      signature,
      action: "create",
    }),
  })
    .catch(networkErrorHandler)
    .then(defaultHandler)
    .catch((err) => {
      hitEvent(hits.createPackLambdaRequestError);

      throw err;
    });
}

function setUserClientData(data) {
  userClientData = data;
}

export default {
  checkAddress,
  createPack,
  setUserClientData,
};