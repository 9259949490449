import React from 'react';
import AppContext from "../../contexts/AppContext";
import {
  fileToJson,
  webviewAppIds,
  webviewCall, webviewConsumable,
  webviewInApp,
  webviewOpenBrowser,
  webviewRestore
} from "../../utils/webview";
import {photolabTask} from "../../photolab/api";
import PhotolabTaskBuilder from "../../photolab/PhotolabTaskBuilder";
import PhotolabTaskImageUrl from "../../photolab/PhotolabTaskImageUrl";
import PhotolabTaskCollageMethod from "../../photolab/PhotolabTaskCollageMethod";
import {normalizeError} from "../../photolab/handlers/helpers";
import clientStorage from "../../utils/client-storage";
import uploadHandler from "../../utils/upload.handler";
import neuroApiHelper from "../../helpers/neuro-api.helper";
import {generatePath} from "react-router";
import routes from "../../routes";
import i18n from "../../i18n";
import {assetUrl, runOnceByLocalStorage, runOnceBySessionStorage} from "../../utils/etc";
import ImageErrorModal from "./ImageErrorModal";
import ImageView from "./ImageView";
import {imageStatus} from "./shared";
import * as webviewUtils from "../../utils/webview";
import {hitEvent, hitEventOnce, hits, logEvent, userEvents} from "../../utils/log";
import MessageModal from "../shared/MessageModal";
import Processing from "../../photolab/Processing"
import processingManager from "../../photolab/ProcessingManager";
import {getProcessingConfig} from "../../photolab/config";
import Creative from "../../photolab/Creative";
import DuplicatesModal from "./DuplicatesModal";
import faceComparisonApiHelper from "../../helpers/face-comparison-api.helper";
import DifferentPeopleModal from "./DifferentPeopleModal";
import {isWebviewApp} from "../../utils/config.utils";
import LimitReachedModal from "../shared/LimitReachedModal";
import tooncoinHelper from "../../helpers/tooncoin-helper";
import {webviewAnalyticsEventIfTooncoin} from "../../utils/tooncoin-log";
import UpdateAppModal from "../shared/UpdateAppModal";
import SubscribeModal from "../shared/SubscribeModal";
import DisclaimerModal from "../shared/DisclaimerModal";

const minImagesThreshold = 10;
const maxImagesThreshold = 20;
const checkImageTemplateId = 7381;

const buttonActions = {
  subscription: "subscription",
  subscriptionUpgrade: "subscription_upgrade",
  purchase: "purchase",
  proceed: "proceed",
};

export default class CreatePage extends React.Component {

  state = {
    isEditMode: false,
    images: clientStorage.getLatestSelectedImages(),
    buttonAction: buttonActions.proceed,
  };

  fileFieldRef = React.createRef();

  constructor(props) {
    super(props);
    this.isTooncoinApp = isWebviewApp(webviewAppIds.tooncoin);
  }

  componentDidMount() {
    if (!this.isTooncoinApp && !window.clientConfig.isPro) {
      if (window.clientConfig.features.isSubscriptionPayModel) {
        if (window.clientConfig.isTrial) {
          this.setState({buttonAction: buttonActions.subscriptionUpgrade});
        } else {
          this.setState({buttonAction: buttonActions.subscription});
        }
      } else if (window.clientConfig.features.isPurchasePayModel) {
        const userInApps = this.context.activeIds.filter((item) => item.type === "inapp");
        if (userInApps.isEmpty()) {
          this.setState({buttonAction: buttonActions.purchase});
        }
      }
    }

    const configs = getProcessingConfig().getCreatives()
    if (configs.isNotEmpty()) {
      this.startProcessing();
    }

    logEvent(userEvents.CREATE_VISIT);
    webviewAnalyticsEventIfTooncoin("photo_chooser_screen_shown", "", "pack_create");

    window.webviewEventsListeners.photoSelected.setListener(this.handleWebviewFileSelected);
    window.webviewEventsListeners.backPress.push(() => {
      this.props.history.replace(routes.INDEX);
      return true;
    });

    this.context.hideLoader();
    this.checkPendingImages();
  }

  componentWillUnmount() {
    window.webviewEventsListeners.photoSelected.removeListener();
    window.webviewEventsListeners.backPress.pop();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    clientStorage.setLatestSelectedImages(this.state.images.map((image) => {
      return Object.assign({}, image, {checked: false});
    }));

    if (this.state.images.length > 0) {
      runOnceByLocalStorage("hit_userSelectPhoto", () => {
        hitEvent(hits.userSelectPhoto);
      });

      hitEventOnce(hits.selectFirstPhotoOnce);
      logEvent(userEvents.PHOTO_SELECT);
      if (this.state.images.length >= minImagesThreshold) {
        hitEventOnce(hits.selectMinimumPhotosOnce);
        logEvent(userEvents.PHOTO_SELECT_MINIMUM);
      }
    }

    this.checkPendingImages();
    this.hitAmountSelectedImages();
  }

  startProcessing = () => {
    if (this.state.images.isEmpty()) {
      processingManager.clear();
    }

    let processing = processingManager.restore();
    if (!processing) {
      processing = new Processing();
      processing.setId(Date.now());
      processing.setLanguage(window.clientConfig.lang);
      processing.setExtra(Processing.EXTRA_CREATED_AT, Date.now());
    }

    processingManager.start(processing);
  }

  checkPendingImages = () => {
    this.state.images
      .filter((image) => image.status === imageStatus.pending)
      .forEach((image) => this.checkImage(image));
  };

  hitAmountSelectedImages = () => {
    const hitsIds = [
      9320, 9321, 9322, 9323, 9324,
      9325, 9326, 9327, 9328, 9329,
    ];

    const validImagesAmount = Math.min(
      this.state.images.count((image) => image.status === imageStatus.valid),
      10
    );

    for (let i = 0; i < validImagesAmount; i++) {
      runOnceBySessionStorage("CreatePage_SelectedImage_" + i, () => {
        hitEvent(hitsIds[i]);
      });
    }
  }

  requestPhotoChooser = () => {
    webviewCall("nativePhotoSelect", {
      func: "onNativeAppPhotoSelected",
      use_crop: 0,
      // num_photos: getAmountPhotos(),
      num_photos: 10,
      show: "gallery",
      tab: "faces",
      neurocamera: 1,
    });
  };

  updateProcessing = (image) => {
    const configs = getProcessingConfig().getCreatives();
    if (configs.isEmpty()) {
      return;
    }

    const creatives = processingManager.processing.creatives;

    const unusedConfig = configs
      .filter((config) => creatives.findIndex((creative) => creative.templateId === config.templateId) === -1)
      .first();

    if (unusedConfig) {
      processingManager.processing.addFile(image, `@${unusedConfig.templateId}`)
      processingManager.processing.addCreative(new Creative()
        .configureByConfig(unusedConfig)
        .setFileId(image.id)
      );

      processingManager.update();
    }
  }

  checkImage = (image) => {
    const taskConfig = new PhotolabTaskBuilder()
      .addImage(new PhotolabTaskImageUrl(image.url))
      .addMethod(new PhotolabTaskCollageMethod(checkImageTemplateId));

    photolabTask(taskConfig.buildToJs())
      .then((res) => {
        image.resultUrl = res.resultUrl.replace("http://", "https://");
        image.status = imageStatus.valid;

        this.updateProcessing(image);
        this.setState({images: this.state.images.slice()});
      })
      .catch((err) => {
        image.error = normalizeError(err);
        image.status = imageStatus.invalid;

        this.setState({images: this.state.images.slice()});
      });
  };

  handleWebviewFileSelected = (data) => {
    if (!data || !data.photos || data.photos.length === 0) {
      return;
    }

    const stateImages = this.state.images.slice();
    const duplicates = [];

    data.photos
      .map((image) => image.image_url.replace("http://", "https://"))
      .map((image) => fileToJson(image))
      .forEach((image) => {
        if (stateImages.findIndex((_) => _.hash === image.hash) === -1) {
          image.status = imageStatus.pending;
          stateImages.push(image);
        } else {
          if (duplicates.findIndex((_) => _.hash === image.hash) === -1) {
            duplicates.push(stateImages.find((_) => _.hash === image.hash));
          }
          duplicates.push(image);
        }
      });

    this.setState({
      images: stateImages.filter((i) => i.status !== imageStatus.invalid)
    });

    if (duplicates.length > 0) {
      // runOnceBySessionStorage("CreatePage_DuplicatesModal", () => {
        this.context.pushModal(<DuplicatesModal
          key="CreatePage_DuplicatesModal"
          duplicates={duplicates}
        />);
      // });
    }
  };

  handleBrowserFileSelected(images) {
    this.context.showLoader();

    const stateImages = this.state.images.slice();
    // const hashes = stateImages.map((i) => i.hash);
    const duplicates = [];

    Promise.all([...images].map((image) => uploadHandler(image, /*hashes*/).catch(() => {/* ignore */})))
      .then((images) => {
        images
          .filter(Boolean)
          .forEach((image) => {
            if (stateImages.findIndex((_) => _.hash === image.hash) === -1) {
              image.status = imageStatus.pending;
              stateImages.push(image);
            } else {
              if (duplicates.findIndex((_) => _.hash === image.hash) === -1) {
                duplicates.push(stateImages.find((_) => _.hash === image.hash));
              }
              duplicates.push(image);
            }
          });

        this.setState({
          images: stateImages.filter((i) => i.status !== imageStatus.invalid)
        }, this.context.hideLoader);

        if (duplicates.length > 0) {
          this.context.pushModal(<DuplicatesModal
            key="CreatePage_DuplicatesModal"
            duplicates={duplicates}
          />);
        }
      })
      .catch(console.error);
  }

  handleRemoveImages = () => {
    const images = this.state.images.filter((i) => !i.checked);

    this.setState({
      isEditMode: false,
      images,
    });
  };

  handleRemoveImage = (image) => {
    const pos = this.state.images.findIndex((_) => _.hash === image.hash);

    if (pos > -1) {
      this.state.images.splice(pos, 1);
      this.setState({
        images: this.state.images.slice(),
      });
    }
  };

  handleImageClick = (image) => {
    if (image.status === imageStatus.invalid) {
      this.context.pushModal(<ImageErrorModal
        key="CreatePage_ImageErrorModal"
        image={image}
        hideCloseButton={true}
        onOkClick={() => this.handleRemoveImage(image)}
      />);
      return;
    }

    image.checked = !image.checked;

    if (Boolean(window.navigator.vibrate)) {
      window.navigator.vibrate(200);
    }

    this.setState({
      isEditMode: this.state.images.count(_ => _.checked) > 0,
      images: this.state.images.slice(),
    });
  };

  handleImageLongClick = (image) => {
    this.handleImageClick(image);
  };

  handleCreatePack = (images, gender) => {
    logEvent(userEvents.PACK_CREATE_REQUEST, {
      images_count: images.length,
    });

    if (window.clientConfig.features.isSubscriptionPayModel && window.clientConfig.isPro && this.context.activeIds.isEmpty()) {
      this.showLimitReachedModal();
      return;
    }

    this.context.showLoader();

    if (isWebviewApp(webviewAppIds.tooncoin)) {
      this.context.showLoader();
      import("../shared/TooncoinConnectModal.js").then((module) => {
        const TooncoinConnectModal = module.default;
        this.context.hideLoader();
        this.context.pushModal(<TooncoinConnectModal
          key="CreatePage_TooncoinConnectModal"
          hideCloseButton={true}
          signMessage={false}
          autoStart={true}
          onSuccess={(account, signature) => {
            this.context.setAccount(account);
            this.context.showLoader();

            tooncoinHelper.createPack(images, gender, account, signature)
              .then((res) => {
                webviewAnalyticsEventIfTooncoin("pack_generation_start", "", "pack_create");

                this.handlePackCreated(res)
              })
              .catch(this.handleError);
          }}
        />);
      });
    } else {
      if (this.context.activeIds.isEmpty()) {
        this.context.pushModal(<LimitReachedModal key="CreatePage_LimitReachedModal" />);
        return;
      }

      neuroApiHelper.createPack(images, gender, this.context.activeIds.first())
        .then(this.handlePackCreated)
        .catch((err) => {
          if (err.name === "NeuroApiResponseError") {
            console.log("Create pack request is failed. Response body: " + JSON.stringify(err.response));
          }

          throw err;
        })
        .catch(this.handleError);
    }
  };

  handlePackCreated = (pack) => {
    if (pack.error) {
      this.handleError(new Error(pack.error));
      return;
    }

    if (pack.ok === false) {
      this.handleError(new Error(pack.message));
      return;
    }

    hitEvent(hits.packCreated);
    hitEventOnce(hits.packCreatedOnce);
    logEvent(userEvents.PACK_CREATED, {
      pack_id: pack.id,
      images_count: pack.images.length,
    });

    if (window.clientConfig.isWebview) {
      webviewUtils.webviewCheckPhotoPack(pack.id);
    }

    this.setState({images: []});

    neuroApiHelper.fetchPacks().then((res) => {
      this.context.setPacks(res.packs);
      this.context.setActiveIds(res.active_ids);
      this.props.history.replace(generatePath(routes.PACK, {id: pack.id}));
    }).catch(() => {
      this.props.history.replace(generatePath(routes.PACK, {id: pack.id}));
    });
  };

  handleError = (err) => {
    console.error(err);

    const message = err.name === "NetworkError"
      ? i18n.t("error__network_message")
      : i18n.t("error__default_message");

    this.context.pushModal(<MessageModal
      key="CreatePage_ErrorModal"
      headingText={i18n.t("error__error")}
      messageText={message}
      buttonText={"OK"}
    />);

    this.context.hideLoader();
  };

  handleProcessClick = () => {
    logEvent(userEvents.CREATE_PROCEED_PRESS);
    hitEventOnce(hits.createProceedPressOnce);
    webviewAnalyticsEventIfTooncoin("button_tapped", "proceed", "pack_create");

    clientStorage.setShouldRedirectToCreate(false);

    this.handleProcess();
  };

  handleProcess = () => {
    const images = this.state.images
      .filter((image) => image.status === imageStatus.valid)
      .map((image) => image.url)
      .slice(0, maxImagesThreshold);

    if (images.length < minImagesThreshold) {
      return;
    }

    this.context.showLoader(() => {
      faceComparisonApiHelper
        .compare(images)
        .then((result) => {
          if (result.unmatched.isNotEmpty() || result.multiple_faces.isNotEmpty()) {
            this.context.pushModal(<DifferentPeopleModal
              key="CreatePage_DifferentPeopleModal"
              onConfirm={() => this.handleCreatePack(images, result.gender)}
              hideCloseButton={true}
              data={result}
            />);

            this.context.hideLoader();
            return;
          }

          this.handleCreatePack(images, result.gender);
        })
        .catch((err) => {
          console.error(err);

          this.context.pushModal(<MessageModal
            key="CreatePage_ErrorModal"
            headingText={i18n.t("error__error")}
            messageText={i18n.t(err.name === "NetworkError" ? "error__network_message" : "error__default_message")}
            buttonText={"OK"}
          />);
          this.context.hideLoader();
        });
    });
  };

  handleAddPhotosClick = () => {
    webviewAnalyticsEventIfTooncoin("button_tapped", "select_photos", "pack_create");

    if (window.clientConfig.isWeb) {
      if (this.fileFieldRef) {
        this.fileFieldRef.value = "";
        this.fileFieldRef.click();
      }
    } else {
      this.requestPhotoChooser();
    }
  };

  handleGoToIndexPage = () => {
    this.props.history.push(routes.INDEX);
  };

  handleStartEditMode = () => {
    this.state.images.forEach((image) => image.checked = false);
    this.setState({isEditMode: true});
  };

  handleCancelEditMode = () => {
    this.state.images.forEach((image) => image.checked = false);
    this.setState({isEditMode: false});
  };

  handleGoToSamplePackPage = () => {
    this.props.history.push(routes.SAMPLE_PACK);
  };

  showTooncoinConnectModal = () => {
    this.context.showLoader();
    import("../shared/TooncoinConnectModal.js").then((module) => {
      const TooncoinConnectModal = module.default;
      this.context.hideLoader();
      this.context.pushModal(<TooncoinConnectModal
        key="CreatePage_TooncoinConnectModal"
        hideCloseButton={true}
        onSuccess={(account) => {
          this.context.setAccount(account);
          this.handleProcess();
        }}
      />);
    });
  };

  showLimitReachedModal = () => {
    logEvent(userEvents.INDEX_GO_PRESS_LIMIT_REACHED, {
      app_data: neuroApiHelper.getUserCredentials(),
      backend_data: {
        packs: this.context.packs,
        active_ids: this.context.activeIds,
      },
    });

    this.context.pushModal(<LimitReachedModal key="CreatePage_LimitReachedModal" />);
  };

  handleSubscribeButtonClick = () => {
    logEvent(userEvents.CREATE_PROCEED_PRESS);
    clientStorage.setShouldRedirectToCreate(true);

    webviewInApp(this.context.subscriptionSku.sku);
  };

  handlePurchaseButtonClick = () => {
    logEvent(userEvents.CREATE_PROCEED_PRESS);
    clientStorage.setShouldRedirectToCreate(true);

    webviewConsumable(this.context.purchaseSku.sku);
  };

  handleStartClick = () => {
    logEvent(userEvents.CREATE_PROCEED_PRESS);
    clientStorage.setShouldRedirectToCreate(true);

    if (this.isTooncoinApp) {
      this.showTooncoinConnectModal();
      return;
    }

    if (!window.clientConfig.features.isSupportGetUserIds) {
      this.context.pushModal(<UpdateAppModal key="CreatePage_UpdateAppModal" />);
      return;
    }

    const purchasedItems = this.context.activeIds.count((id) => id.type !== "free");
    if (purchasedItems === 0 && !window.clientConfig.isPro) {
      logEvent(userEvents.INDEX_GO_PRESS_SUBSCRIBE, {
        app_data: neuroApiHelper.getUserCredentials(),
        backend_data: {
          packs: this.context.packs,
          active_ids: this.context.activeIds,
        },
      });

      this.context.pushModal(<SubscribeModal key="CreatePage_SubscribeModal" />);
      return;
    }

    if (this.context.activeIds.isEmpty()) {
      logEvent(userEvents.INDEX_GO_PRESS_LIMIT_REACHED, {
        app_data: neuroApiHelper.getUserCredentials(),
        backend_data: {
          packs: this.context.packs,
          active_ids: this.context.activeIds,
        },
      });

      this.context.pushModal(<LimitReachedModal key="CreatePage_LimitReachedModal" />);
      return;
    }

    const sessionIdx = parseInt(window.clientConfig.webviewParams["session_idx"] || 1);

    if (sessionIdx !== clientStorage.getSessionIdx()) {
      clientStorage.setSessionIdx(sessionIdx);
      this.context.pushModal(<DisclaimerModal
        key="CreatePage_DisclaimerModal"
        onConfirm={this.handleProcess}
        hideCloseButton={true}
      />);
      return;
    }

    clientStorage.setShouldRedirectToCreate(false);

    this.handleProcess();
  };

  handleGoToPrivacyPolicy = () => {
    webviewOpenBrowser("https://pho.to/privacy.html", window.clientConfig.isWebviewIOS ? "safari" : undefined);
  };

  handleGoToTerms = () => {
    webviewOpenBrowser("https://pho.to/terms.html", window.clientConfig.isWebviewIOS ? "safari" : undefined);
  };

  handleRestore = () => {
    webviewRestore();
  };

  render() {
    const validImagesCount = this.state.images.count((image) => {
      return image.status === imageStatus.valid;
    });

    const invalidImagesCount = this.state.images.count((image) => {
      return image.status === imageStatus.invalid;
    });

    const selectButtonText = this.state.images.length > 0
      ? i18n.t("button__select_more")
      : i18n.t("button__select_photos");

    const sectionSelectClass = ["container"];

    if (this.state.images.length > 0) {
      sectionSelectClass.push("select-photo");
    } else {
      sectionSelectClass.push("upload-photo");
    }

    let button;

    if (this.state.buttonAction === buttonActions.subscription || this.state.buttonAction === buttonActions.subscriptionUpgrade) {
      const price = this.context.subscriptionSku.price.replace(/\s+/, "\u00A0");

      sectionSelectClass.push("subscription");

      const termsInfo = <React.Fragment>
        <p className="subscribe-text" dangerouslySetInnerHTML={{__html: i18n.t("footer_pro_info")}}/>
        <div className="subscribe-footer">
          <button onClick={this.handleGoToTerms} children={i18n.t("terms")}/>
          <button onClick={this.handleGoToPrivacyPolicy} children={i18n.t("privacy_policy")}/>
          <button onClick={this.handleRestore} children={i18n.t("restore")}/>
        </div>
      </React.Fragment>;

      if (this.state.buttonAction === buttonActions.subscriptionUpgrade) {
        button = <div className="subscribe-banner" hidden={validImagesCount < minImagesThreshold}>
          <h2>Checkout</h2>
          <p>{i18n.t("subscribe_message", {price})}</p>
          <button
            className="btn btn-violet btn-shadow subscribe-btn"
            onClick={this.handleSubscribeButtonClick}>
            {i18n.t("button_continue")}
            <SvgArrow />
          </button>
          <p className="price-text">&nbsp;</p>
          {termsInfo}
        </div>;
      } else {
        button = <div className="subscribe-banner" hidden={validImagesCount < minImagesThreshold}>
          <h2>Checkout</h2>
          <p>{i18n.t("subscribe_message", {price})}</p>
          <button
            className="btn btn-violet btn-shadow subscribe-btn"
            onClick={this.handleSubscribeButtonClick}>
            {i18n.t("button_continue")}
            <SvgArrow />
          </button>
          <p className="price-text">&nbsp;</p>
          {termsInfo}
        </div>;
      }
    } else if (this.state.buttonAction === buttonActions.purchase) {
      const price = this.context.purchaseSku.price.replace(/\s+/, "\u00A0");

      button = <div className="subscribe-banner purchase-banner" hidden={validImagesCount < minImagesThreshold}>
        <h2>Checkout</h2>
        <p>Get 50+ unique avatars with {price} one-time purchase</p>
        <button
          className="btn btn-violet btn-shadow subscribe-btn"
          onClick={this.handlePurchaseButtonClick}>
          {i18n.t("button_continue", {price})}
          <SvgArrow />
        </button>
      </div>;
    } else {
      button = <button
        className="btn btn-violet btn-shadow"
        onClick={this.handleProcessClick}
        hidden={validImagesCount < minImagesThreshold}>
        {i18n.t("button__proceed")}
      </button>;
    }

    return <React.Fragment>
      <div className={sectionSelectClass.join(" ")}>
        <header>
          <button onClick={this.handleGoToIndexPage}>
            <svg viewBox="0 0 24 24"><path d="m5.7 10.5 8.4-8.4L12 0 0 12l12 12 2.1-2.1-8.4-8.4H24v-3z" fill="#fff" fillRule="evenodd"/></svg>
          </button>
          <h1>{i18n.t("create_page__title")}</h1>
        </header>

        <div className="images-grid" hidden={this.state.images.length === 0}>
          <div className="images-grid-header">
            <p
              className="btn-upload"
              dangerouslySetInnerHTML={{__html: i18n.t("create_page__photos_count", {num: validImagesCount})}}
            />
            <p
              className="error-text"
              dangerouslySetInnerHTML={{__html: i18n.t("create_page__photos_error")}}
              hidden={invalidImagesCount === 0}
            />

            <button
              className="btn-select-delete"
              hidden={this.state.isEditMode}>
              {i18n.t("button__tap_to_delete")}
            </button>

            <div className="btns-option-container" hidden={!this.state.isEditMode}>
              <button
                className="btn-option"
                onClick={this.handleCancelEditMode}>
                {i18n.t("button__cancel")}
              </button>

              <button
                className="btn-option"
                onClick={this.handleRemoveImages}>
                <img src={assetUrl("assets/images/icon-trash.png")} alt="" />
                {i18n.t("button__delete")}
              </button>
            </div>
          </div>

          <div className="images-grid-list">
            <button
              key="button_add_more_photos"
              className="btn-more"
              onClick={this.handleAddPhotosClick}>
              <div className="btn-more-content">
                <img src={assetUrl("assets/images/icon-camera-black.png")} alt="" />
                {i18n.t("button__add_more")}
              </div>
            </button>

            {this.state.images.map((image) => <ImageView
              key={image.url}
              image={image}
              onClick={this.handleImageClick}
              onLongClick={this.handleImageLongClick}
              isSelectable={this.state.isEditMode}
            />)}
          </div>
        </div>

        <ul className="list-conditions" hidden={validImagesCount >= minImagesThreshold}>
          <li>
            <img src={assetUrl("assets/images/icon-double-exclamation.png")} alt="" />
            <p dangerouslySetInnerHTML={{__html: i18n.t("create_page__rule_1")}} />
          </li>
          <li>
            <img src={assetUrl("assets/images/icon-women.png")} alt="" />
            <p dangerouslySetInnerHTML={{__html: i18n.t("create_page__rule_2")}} />
          </li>
          <li>
            <img src={assetUrl("assets/images/icon-cross.png")} alt="" />
            <p dangerouslySetInnerHTML={{__html: i18n.t("create_page__rule_3")}} />
          </li>
          {/*<li>*/}
          {/*  <img src={assetUrl("assets/images/icon-camera.png")} alt="" />*/}
          {/*  <p dangerouslySetInnerHTML={{__html: i18n.t("create_page__rule_4")}} />*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <img src={assetUrl("assets/images/icon-sunrise.png")} alt="" />*/}
          {/*  <p dangerouslySetInnerHTML={{__html: i18n.t("create_page__rule_5")}} />*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <img src={assetUrl("assets/images/icon-squinting-face.png")} alt="" />*/}
          {/*  <p dangerouslySetInnerHTML={{__html: i18n.t("create_page__rule_6")}} />*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <img src={assetUrl("assets/images/icon-upside-down-face.png")} alt="" />*/}
          {/*  <p dangerouslySetInnerHTML={{__html: i18n.t("create_page__rule_7")}} />*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <img src={assetUrl("assets/images/icon-no-entry.png")} alt="" />*/}
          {/*  <p dangerouslySetInnerHTML={{__html: i18n.t("create_page__rule_8")}} />*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <img src={assetUrl("assets/images/icon-no-entry.png")} alt="" />*/}
          {/*  <p dangerouslySetInnerHTML={{__html: i18n.t("create_page__rule_9")}} />*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <img src={assetUrl("assets/images/icon-no-entry.png")} alt="" />*/}
          {/*  <p dangerouslySetInnerHTML={{__html: i18n.t("create_page__rule_10")}} />*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <img src={assetUrl("assets/images/icon-no-entry.png")} alt="" />*/}
          {/*  <p dangerouslySetInnerHTML={{__html: i18n.t("create_page__rule_11")}} />*/}
          {/*</li>*/}
        </ul>

        <button
          className="btn btn-transparent mt-24"
          hidden={validImagesCount >= minImagesThreshold}
          onClick={this.handleGoToSamplePackPage}>
          <img className='image-top' src={assetUrl("assets/images/icon-magic.png")} alt="" />
          {i18n.t("button__show_me_the_sample")}
        </button>

        <div className="btn-container">
          <button
            className="btn btn-shadow btn-violet"
            onClick={this.handleAddPhotosClick}
            hidden={validImagesCount >= minImagesThreshold}>
            {selectButtonText}
          </button>

          {button}

          <input
            className="file-field-hidden"
            type="file"
            accept="image/*"
            multiple
            ref={(ref) => this.fileFieldRef = ref}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => this.handleBrowserFileSelected(e.target.files)} />
        </div>
      </div>
    </React.Fragment>;
  }
}

CreatePage.contextType = AppContext;

const SvgArrow = () => <svg viewBox="0 0 25 8">
  <path d="M24.354 4.354a.5.5 0 0 0 0-.708L21.172.464a.5.5 0 1 0-.707.708L23.293 4l-2.828 2.828a.5.5 0 1 0 .707.708l3.182-3.182zM0 4.5h24v-1H0v1z"/>
</svg>;

const SvgStars = () => <svg viewBox="0 0 36 42" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="m31.39 9.166-1.033-3.489-3.44-1.094 3.44-1.094L31.458 0l1.101 3.489L36 4.583l-3.441 1.095-1.17 3.488zM10.32 21.73l-2.476-7.867L0 11.47l7.844-2.394 2.408-7.866 2.408 7.866 7.913 2.394-7.913 2.395-2.34 7.866zm4.154 10.156 1.17 3.762 3.784 1.164-3.785 1.162-1.17 3.763-1.17-3.763-3.783-1.162 3.784-1.164 1.17-3.762z" fill="#F8D34F" />
</svg>;