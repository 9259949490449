const VERSION = 1;

const KEY_PREFS = "na:prefs";
const KEY_PREFS_VERSION = "na:prefs:version";

const KEY_CLIENT_TOKEN = "client_token";
const KEY_SPLIT_GROUP_ID = "split_group_id";
const KEY_WEBVIEW_REQUEST_PARAMS = "webview_request_params";
const KEY_FIRST_VISIT_AT = "first_visit_at";
const KEY_SELECTED_LANG = "selected_lang";
const KEY_LATEST_SELECTED_IMAGES = "latest_selected_images";

class ClientStorage {

  constructor() {
    this.load();

    const currentVersion = parseInt(window.localStorage.getItem(KEY_PREFS_VERSION) || 1);
    if (currentVersion !== VERSION) {
      this.migrate(currentVersion, VERSION);
      window.localStorage.setItem(KEY_PREFS_VERSION, "" + VERSION);
    }
  }

  load() {
    const prefs = window.localStorage.getItem(KEY_PREFS);
    if (prefs) {
      this.prefs = JSON.parse(prefs);
    } else {
      this.prefs = {};
    }
  }

  migrate(fromVersion, toVersion) {
    // ...
  }

  commit() {
    window.localStorage.setItem(KEY_PREFS, JSON.stringify(this.prefs));
  }

  setBoolean(key, value) {
    this.prefs[key] = !!value;
    this.commit();
  }

  getBoolean(key, defaultValue) {
    if (this.prefs[key] !== undefined) {
      return !!this.prefs[key];
    } else {
      return defaultValue;
    }
  }

  setInteger(key, value) {
    this.prefs[key] = parseInt(value);
    this.commit();
  }

  getInteger(key, defaultValue) {
    if (this.prefs[key] !== undefined) {
      return parseInt(this.prefs[key]);
    } else {
      return defaultValue;
    }
  }

  setString(key, value) {
    this.prefs[key] = "" + value;
    this.commit();
  }

  getString(key, defaultValue) {
    if (this.prefs[key] !== undefined) {
      return "" + this.prefs[key];
    } else {
      return defaultValue;
    }
  }

  // ---

  getClientToken() {
    return this.getString(KEY_CLIENT_TOKEN);
  }

  setClientToken(value) {
    this.setString(KEY_CLIENT_TOKEN, value);
  }

  getFirstVisitAt() {
    return this.getInteger(KEY_FIRST_VISIT_AT);
  }

  setFirstVisitAt(value) {
    this.setInteger(KEY_FIRST_VISIT_AT, value);
  }

  hasFirstVisitAt() {
    return this.getInteger(KEY_FIRST_VISIT_AT, Number.MIN_VALUE) !== Number.MIN_VALUE;
  }

  setWebviewRequestParams(value) {
    if (typeof value !== 'object' || value === null) {
      value = {};
    }

    this.prefs[KEY_WEBVIEW_REQUEST_PARAMS] = value;
    this.commit();
  }

  getWebviewRequestParams() {
    if (this.prefs[KEY_WEBVIEW_REQUEST_PARAMS] === undefined) {
      return {};
    }

    return this.prefs[KEY_WEBVIEW_REQUEST_PARAMS];
  }

  getSplitGroupId() {
    return this.getInteger(KEY_SPLIT_GROUP_ID, undefined);
  }

  setSplitGroupId(value) {
    this.setInteger(KEY_SPLIT_GROUP_ID, value);
  }

  getSelectedLang() {
    return this.getString(KEY_SELECTED_LANG, null);
  }

  setSelectedLang(value) {
    this.setString(KEY_SELECTED_LANG, value);
  }

  getLatestSelectedImages() {
    const value = this.prefs[KEY_LATEST_SELECTED_IMAGES];

    return Array.isArray(value)
      ? value
      : [];
  }

  setLatestSelectedImages(value) {
    this.prefs[KEY_LATEST_SELECTED_IMAGES] = value;
    this.commit();
  }

  setShouldRedirectToCreate(flag) {
    this.setBoolean("should_redirect_to_create", flag);
  }

  getShouldRedirectToCreate() {
    return this.getBoolean("should_redirect_to_create", false);
  }

  setPackProcessingTimeIsLogged(packId) {
    this.setBoolean("pack_processing_time_is_logged:" + packId, true);
  }

  getPackProcessingTimeIsLogged(packId) {
    return this.getBoolean("pack_processing_time_is_logged:" + packId, false);
  }

  setPackProgressCountdown(packId, millis) {
    this.setInteger("pack_progress_countdown:" + packId, millis);
  }

  getPackProgressCountdown(packId) {
    return this.getInteger("pack_progress_countdown:" + packId);
  }

  removePackProgressCountdown(packId) {
    delete this.prefs["pack_progress_countdown:" + packId];
    this.commit();
  }

  setFirstDownloadByPack(packId) {
    this.setBoolean("pack_first_download_by_pack:" + packId, true);
  }

  getFirstDownloadByPack(packId) {
    return this.getBoolean("pack_first_download_by_pack:" + packId, false);
  }

  setSessionIdx(id) {
    this.setInteger("session_idx", id);
  }

  getSessionIdx() {
    return this.getInteger("session_idx");
  }

  removeAuthAccount() {
    delete this.prefs["auth_account"];
    this.commit();
  }

  setAuthAccount(account) {
    this.prefs["auth_account"] = account;
    this.commit();
  }

  getAuthAccount() {
    return this.prefs["auth_account"];
  }
}

export default new ClientStorage();
