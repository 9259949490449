import React from "react";
import AppContext from "../../contexts/AppContext";
import routes from "../../routes";
import i18n from "../../i18n";
import {assetUrl, throttle} from "../../utils/etc";
import DisclaimerModal from "../shared/DisclaimerModal";
import LatestPacksView from "../shared/LatestPacksView";
import LimitReachedModal from "../shared/LimitReachedModal";
import {hitEvent, hitEventOnce, hits, logEvent, userEvents} from "../../utils/log";
import SubscribeModal from "../shared/SubscribeModal";
import {isWebviewApp} from "../../utils/config.utils";
import {webviewAppIds, webviewConsumable} from "../../utils/webview";
import {webviewInApp, webviewOpenBrowser, webviewRestore} from "../../utils/webview";
import PuzzleContainer from "./PuzzleContainer";
import neuroApiHelper from "../../helpers/neuro-api.helper";
import clientStorage from "../../utils/client-storage";
import UpdateAppModal from "../shared/UpdateAppModal";
import {webviewAnalyticsEventIfTooncoin} from "../../utils/tooncoin-log";

const buttonActions = {
  subscription: "subscription",
  subscriptionUpgrade: "subscription_upgrade",
  purchase: "purchase",
  start: "start",
};

export default class IndexPage extends React.Component {

  state = {
    buttonAction: buttonActions.start,
  };

  constructor(props) {
    super(props);
    this.isTooncoinApp = isWebviewApp(webviewAppIds.tooncoin);
  }

  componentDidMount() {
    if (!this.isTooncoinApp && window.clientConfig.features.payOnIndex && !window.clientConfig.isPro) {
      if (window.clientConfig.features.isSubscriptionPayModel) {
        if (window.clientConfig.isTrial) {
          this.setState({buttonAction: buttonActions.subscriptionUpgrade});
        } else {
          this.setState({buttonAction: buttonActions.subscription});
        }
      } else if (window.clientConfig.features.isPurchasePayModel) {
        const userInApps = this.context.activeIds.filter((item) => item.type === "inapp");
        if (userInApps.isEmpty()) {
          this.setState({buttonAction: buttonActions.purchase});
        }
      }
    }

    // когда открыто в модальном окне нет вызова webview_tab_selected
    // поэтому вызываем событие на загрузку страницы
    if (window.clientConfig.isWebviewAndroid && isWebviewApp(webviewAppIds.newprofilepic, webviewAppIds.tooncoin)) {
      this.logIndexVisit();
      webviewAnalyticsEventIfTooncoin("tab_selected", "ai_avatars");
    }

    this.webviewTabSelected$ = window.webviewEventsListeners.tabSelected.subscribe((v) => {
      if (v > 0) {
        this.logIndexVisit();
      }
    }, true);

    this.context.hideLoader();
  }

  componentWillUnmount() {
    this.webviewTabSelected$ && this.webviewTabSelected$.unsubscribe();
  }

  logIndexVisit = throttle(2000, () => {
    hitEvent(hits.indexVisit);
    hitEventOnce(hits.indexVisitOnce);
    logEvent(userEvents.INDEX_VISIT);
  });

  showDisclaimerModal = () => {
    this.context.pushModal(<DisclaimerModal
      key="IndexPage_DisclaimerModal"
      onConfirm={this.handleGoToCreatePage}
      hideCloseButton={true}
    />);
  }

  showLimitReachedModal = () => {
    logEvent(userEvents.INDEX_GO_PRESS_LIMIT_REACHED, {
      app_data: neuroApiHelper.getUserCredentials(),
      backend_data: {
        packs: this.context.packs,
        active_ids: this.context.activeIds,
      },
    });

    this.context.pushModal(<LimitReachedModal key="IndexPage_LimitReachedModal" />);
  };

  showTooncoinConnectModal = () => {
    this.context.showLoader();
    import("../shared/TooncoinConnectModal.js").then((module) => {
      const TooncoinConnectModal = module.default;
      this.context.hideLoader();
      this.context.pushModal(<TooncoinConnectModal
        key="IndexPage_TooncoinConnectModal"
        hideCloseButton={true}
        onSuccess={(account) => {
          this.context.setAccount(account);
          this.handleGoToCreatePage();
        }}
      />);
    });
  };

  handleSubscribeButtonClick = () => {
    webviewInApp(this.context.subscriptionSku.sku);
  };

  handlePurchaseButtonClick = () => {
    webviewConsumable(this.context.purchaseSku.sku);
  };

  handleStartClick = () => {
    hitEvent(hits.indexGoPress);
    hitEventOnce(hits.indexGoPressOnce);
    logEvent(userEvents.INDEX_GO_PRESS);

    if (this.isTooncoinApp) {
      webviewAnalyticsEventIfTooncoin("button_tapped", "go", "landing");
      this.showTooncoinConnectModal();
      return;
    }

    if (!window.clientConfig.features.isSupportGetUserIds) {
      this.context.pushModal(<UpdateAppModal key="IndexPage_UpdateAppModal" />);
      return;
    }

    if (window.clientConfig.features.payOnCreate) {
      // если юзер уже подписан и у юзера есть паки
      // но нет покупок то говорим что лимит достигнут на этом экране
      if (window.clientConfig.features.isSubscriptionPayModel && window.clientConfig.isPro && this.context.activeIds.isEmpty()) {
        this.showLimitReachedModal();
        return;
      }

      this.handleGoToCreatePage();
      return;
    }

    const purchasedItems = this.context.activeIds.count((id) => id.type !== "free");
    if (purchasedItems === 0 && !window.clientConfig.isPro) {
      logEvent(userEvents.INDEX_GO_PRESS_SUBSCRIBE, {
        app_data: neuroApiHelper.getUserCredentials(),
        backend_data: {
          packs: this.context.packs,
          active_ids: this.context.activeIds,
        },
      });

      this.context.pushModal(<SubscribeModal key="IndexPage_SubscribeModal" />);
      return;
    }

    if (this.context.activeIds.isEmpty()) {
      this.showLimitReachedModal();
      return;
    }

    const sessionIdx = parseInt(window.clientConfig.webviewParams["session_idx"] || 1);

    if (sessionIdx !== clientStorage.getSessionIdx()) {
      clientStorage.setSessionIdx(sessionIdx);
      this.showDisclaimerModal();
      return;
    }

    this.handleGoToCreatePage();
  };

  handleGoToCreatePage = () => {
    this.props.history.push(routes.CREATE);
  };

  handleGoToPacksPage = () => {
    this.props.history.push(routes.PACKS);
  }

  handleGoToSamplePackPage = () => {
    webviewAnalyticsEventIfTooncoin("button_tapped", "show_example", "landing");

    this.props.history.push(routes.SAMPLE_PACK);
  };

  handleGoToPrivacyPolicy = () => {
    webviewOpenBrowser("https://pho.to/privacy.html", window.clientConfig.isWebviewIOS ? "safari" : undefined);
  };

  handleGoToTerms = () => {
    webviewOpenBrowser("https://pho.to/terms.html", window.clientConfig.isWebviewIOS ? "safari" : undefined);
  };

  handleRestore = () => {
    webviewRestore();
  };

  handleTextClick = (e) => {
    e.persist();

    if (e.target.nodeName.toLowerCase() === "button") {
      this.handleGoToSamplePackPage();
    }
  };

  render() {
    if (this.isTooncoinApp) {
      return this.renderTooncoin();
    } else {
      return this.renderDefault();
    }
  }

  renderDefault = () => {
    let button;
    let buttonSubText;
    let titleText = i18n.t("landing_page__title_v2");
    let textText = i18n.t("landing_page__text_v2");

    if (this.state.buttonAction === buttonActions.start) {
      button = <button
        className={"btn btn-yellow"}
        onClick={this.handleStartClick}>
        <img src={assetUrl("assets/images/icon-rocket-v2.png")} alt=""/>
        {i18n.t("button__go")}
      </button>;
    } else if (this.state.buttonAction === buttonActions.purchase) {
      const price = this.context.purchaseSku.price.replace(/\s+/, "\u00A0");

      button = <button
        className={"btn btn-yellow btn-full-width"}
        onClick={this.handlePurchaseButtonClick}>
        <img src={assetUrl("assets/images/icon-rocket-v2.png")} alt="" />
        {i18n.t("button_proceed_subscribe_with_price", {price})}
      </button>;
    } else {
      const price = this.context.subscriptionSku.price.replace(/\s+/, "\u00A0");
      textText += " <button>See example</button>";

      if (this.state.buttonAction === buttonActions.subscriptionUpgrade) {
        button = <button
          className={"btn btn-yellow btn-full-width"}
          onClick={this.handleSubscribeButtonClick}>
          <img src={assetUrl("assets/images/icon-rocket-v2.png")} alt="" />
          {i18n.t("button_subscribe_with_price", {price})}
        </button>;
      } else {
        buttonSubText = <p className="info-pro-price">{i18n.t("button_subscribe_sub", {price})}</p>;
        button = <button
          className={"btn btn-yellow btn-full-width"}
          onClick={this.handleSubscribeButtonClick}>
          <img src={assetUrl("assets/images/icon-rocket-v2.png")} alt="" />
          {i18n.t("button_subscribe")}
        </button>;
      }
    }

    return <section className="main-page main-page-v2">
      <PuzzleContainer />
      <div className="bg-main-page">
        <div className="main-page-block">
          <h1>
            <span dangerouslySetInnerHTML={{__html: titleText}} />
          </h1>

          <p
            className="main-page-text"
            onClick={this.handleTextClick}
            dangerouslySetInnerHTML={{__html: textText}}
          />

          {button}

          {/*<button*/}
          {/*  className="btn btn-sample"*/}
          {/*  onClick={this.handleGoToSamplePackPage}>*/}
          {/*  {i18n.t("button__go_to_sample_pack")}*/}
          {/*</button>*/}
        </div>

        <div className="info-pro" hidden={this.state.buttonAction !== buttonActions.subscription && this.state.buttonAction !== buttonActions.subscriptionUpgrade}>
          {buttonSubText}
          <div className="info-pro-text">
            <svg viewBox="0 0 36 42" fill="none">
              <path fillRule="evenodd" clipRule="evenodd" d="m31.39 9.166-1.033-3.489-3.44-1.094 3.44-1.094L31.458 0l1.101 3.489L36 4.583l-3.441 1.095-1.17 3.488zM10.32 21.73l-2.476-7.867L0 11.47l7.844-2.394 2.408-7.866 2.408 7.866 7.913 2.394-7.913 2.395-2.34 7.866zm4.154 10.156 1.17 3.762 3.784 1.164-3.785 1.162-1.17 3.763-1.17-3.763-3.783-1.162 3.784-1.164 1.17-3.762z" fill="#F8D34F"/>
            </svg>
            <p dangerouslySetInnerHTML={{__html: i18n.t("subscribe_message")}} />
          </div>
          <p dangerouslySetInnerHTML={{__html: i18n.t("footer_pro_info")}} />
          <div className="btns-container">
            <button
              onClick={this.handleGoToTerms}
              children={i18n.t("terms")} />
            <button
              onClick={this.handleGoToPrivacyPolicy}
              children={i18n.t("privacy_policy")} />
            <button
              onClick={this.handleRestore}
              children={i18n.t("restore")} />
          </div>
        </div>

        <LatestPacksView
          className="previous-avatars-container-v2"
          onGoToClick={() => this.context.packs.isEmpty()
            ? this.handleGoToSamplePackPage()
            : this.handleGoToPacksPage()} />
      </div>
    </section>;
  };

  renderTooncoin = () => {
    let titleText = i18n.t("landing_page__title_v2");
    let textText = i18n.t("landing_page__text_v2");
    textText += " <button>See example</button>";

    return <section className="main-page main-page-v2">
      <PuzzleContainer />
      <div className="bg-main-page">
        <div className="main-page-block">
          <h1>
            <span dangerouslySetInnerHTML={{__html: titleText}} />
          </h1>

          <p
            className="main-page-text"
            onClick={this.handleTextClick}
            dangerouslySetInnerHTML={{__html: textText}}
          />

          <button
            className={"btn btn-yellow btn-full-width"}
            onClick={this.handleStartClick}>
            <img src={assetUrl("assets/images/icon-rocket-v2.png")} alt="" />
            {i18n.t("button__go")}
          </button>
        </div>

        <div className="info-pro">
          <div className="info-pro-text">
            <svg viewBox="0 0 36 42" fill="none">
              <path fillRule="evenodd" clipRule="evenodd" d="m31.39 9.166-1.033-3.489-3.44-1.094 3.44-1.094L31.458 0l1.101 3.489L36 4.583l-3.441 1.095-1.17 3.488zM10.32 21.73l-2.476-7.867L0 11.47l7.844-2.394 2.408-7.866 2.408 7.866 7.913 2.394-7.913 2.395-2.34 7.866zm4.154 10.156 1.17 3.762 3.784 1.164-3.785 1.162-1.17 3.763-1.17-3.763-3.783-1.162 3.784-1.164 1.17-3.762z" fill="#F8D34F"/>
            </svg>
            <p dangerouslySetInnerHTML={{__html: i18n.t("landing_page__text_tooncoin")}} />
          </div>
        </div>

        <LatestPacksView
          className="previous-avatars-container-v2"
          hidden={this.context.packs.isEmpty()}
          onGoToClick={this.handleGoToPacksPage} />
      </div>
    </section>;
  };
}

IndexPage.contextType = AppContext;
