import React from "react";
import {getOneMorePackInAppSku, getSubscriptionSku} from "../utils/inapp";

const initialState = {
  loader: {
    isHidden: false,
  },
  toast: {
    message: "",
    delay: 5000,
  },
  installedApps: {},
  modals: [],
  packs: [],
  activeIds: [],
  skus: {},
  subscriptionSku: getSubscriptionSku(),
  purchaseSku: getOneMorePackInAppSku(),
};

const AppContext = React.createContext({...initialState});

const AppContextConsumer = AppContext.Consumer;

class AppContextProvider extends React.Component {

  state = {...initialState};

  render() {
    const state = {
      ...this.state,
      setInstalledApps: (apps) => {
        this.setState({installedApps: apps});
      },
      pushModal: (component, cb) => {
        const modals = this.state.modals.slice();
        modals.push(component);

        this.setState({modals}, () => cb && cb());
      },
      popModal: (cb) => {
        const modals = this.state.modals.slice();
        modals.pop();

        this.setState({modals}, () => cb && cb());
      },
      hideLoader: (cb) => {
        const nextState = this.state.loader;
        nextState.isHidden = true;

        this.setState({loader: nextState},() => cb && cb());
      },
      showLoader: (cb) => {
        this.setState({loader: {
          isHidden: false,
        }},() => cb && cb());
      },
      showToast: ({message, delay}) => {
        this.setState({
          toast: {
            messageKey: Date.now(),
            message,
            delay,
          },
        });
      },
      setPacks: (packs) => {
        this.setState({packs});
      },
      setActiveIds: (activeIds) => {
        this.setState({activeIds});
      },
      setSkus: (skus) => {
        const nextState = {
          skus: Object.keys(skus).map((sku) => {
            skus[sku].sku = sku;
            return skus[sku];
          }),
        };

        if (skus[this.state.purchaseSku.sku]) {
          nextState.purchaseSku = skus[this.state.purchaseSku.sku];
        }

        if (skus[this.state.subscriptionSku.sku]) {
          nextState.subscriptionSku = skus[this.state.subscriptionSku.sku];
        }

        this.setState(nextState);
      },
      setAccount: (account) => {
        this.setState({account});
      },
      setSubscriptionSku: (sku) => {
        this.setState({subscriptionSku: sku});
      },
      setPurchaseSku: (sku) => {
        this.setState({purchaseSku: sku});
      },
    };

    return <AppContext.Provider
      value={state}
      children={this.props.children}
    />;
  }
}

export default AppContext;

export {AppContextConsumer, AppContextProvider};
