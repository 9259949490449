import React from "react";
import Modal from "../../components/Modal";
import i18n from "../../i18n";
import {webviewConsumable} from "../../utils/webview";

export default class LimitReachedModal extends Modal {

  componentDidMount() {
    window.webviewEventsListeners.backPress.push(() => {
      this.dismiss();
      return true;
    });
  }

  dismiss(trigger) {
    window.webviewEventsListeners.backPress.pop();
    super.dismiss(trigger);
  }

  handlePurchaseButtonClick = () => {
    webviewConsumable(this.context.purchaseSku.sku);
  };

  renderModal() {
    const price = this.context.purchaseSku.price.replace(/\s+/, "\u00A0");

    return <React.Fragment>
      {/*<h1 dangerouslySetInnerHTML={{__html: i18n.t("limit_reached_title")}} />*/}
      <p dangerouslySetInnerHTML={{__html: i18n.t("limit_reached_message")}} />
      <button
        className="btn btn-white"
        onClick={this.handlePurchaseButtonClick}>
        {i18n.t("limit_reached_button_purchase", {price})}
      </button>
      {/*<button*/}
      {/*  className="btn btn-white"*/}
      {/*  onClick={() => this.dismiss()}>*/}
      {/*  {i18n.t("limit_reached_button_ok")}*/}
      {/*</button>*/}
    </React.Fragment>;
  }
}
